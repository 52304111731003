@tailwind base;

.tw-fixed {
   position: fixed;
}
@media (min-width: 768px){
   .md\:tw-relative {
      position: relative;
   }
}
/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/
.itm-booker, .itm-promotion-portal{
   font-size: 14px;
}

.itm-booker *,
.itm-booker ::before,
.itm-booker ::after,
.itm-promotion-portal *,
.itm-promotion-portal ::before,
.itm-promotion-portal ::after
{
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: theme('borderColor.DEFAULT', currentColor); /* 2 */
}

.itm-booker *::before,
.itm-booker *::after,
.itm-promotion-portal *::before,
.itm-promotion-portal *::after
 {
  --tw-content: '';
}

/*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
5. Use the user's configured `sans` font-feature-settings by default.
6. Use the user's configured `sans` font-variation-settings by default.
*/

.itm-booker, .itm-promotion-portal, .DateRangePicker_picker__fullScreenPortal, .SingleDatePicker_picker__fullScreenPortal{
   font-size: inherit;
   color: #333;
   line-height: 1.42858;
   -webkit-text-size-adjust: 100%; /* 2 */
   -moz-tab-size: 4; /* 3 */
   tab-size: 4; /* 3 */
   font-family: theme('fontFamily.sans', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"); /* 4 */
   /* font-feature-settings: theme('fontFamily.sans[1].fontFeatureSettings', normal); 
   font-variation-settings: theme('fontFamily.sans[1].fontVariationSettings', normal); */
}

/*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/

.itm-booker hr {
  height: 0; /* 1 */
  color: inherit; /* 2 */
  border-top-width: 1px; /* 3 */
}

/*
Add the correct text decoration in Chrome, Edge, and Safari.
*/

.itm-booker abbr:where([title]) {
  text-decoration: underline dotted;
}

/*
Remove the default font size and weight for headings.
*/

.itm-booker h1,
.itm-booker h2,
.itm-booker h3,
.itm-booker h4,
.itm-booker h5,
.itm-booker h6 {
  font-size: inherit;
  font-weight: inherit;
}

/*
Reset links to optimize for opt-in styling instead of opt-out.
*/

.itm-booker a {
  color: inherit;
  text-decoration: inherit;
}

/*
Add the correct font weight in Edge and Safari.
*/

.itm-booker b,
.itm-booker strong {
  font-weight: bolder;
}

/*
Add the correct font size in all browsers.
*/

.itm-booker small {
  font-size: 80%;
}

/*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/

.itm-booker input,
.itm-booker button,
.itm-booker optgroup,
.itm-booker select,
.itm-booker textarea,
.itm-promotion-portal button
{
  font-family: inherit; /* 1 */
  font-feature-settings: inherit; /* 1 */
  font-variation-settings: inherit; /* 1 */
  font-size: 100%; /* 1 */
  font-weight: inherit; /* 1 */
  line-height: inherit; /* 1 */
  color: inherit; /* 1 */
  margin: 0; /* 2 */
  padding: 0; /* 3 */
}

/*
Remove the inheritance of text transform in Edge and Firefox.
*/

.itm-booker button,
.itm-booker select,
.itm-promotion-portal button {
  text-transform: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Remove default button styles.
*/

.itm-booker button,
.itm-booker [type='button'],
.itm-booker [type='reset'],
.itm-booker [type='submit'],
.itm-promotion-portal button,
.itm-promotion-portal [type='button']
{
  -webkit-appearance: button; /* 1 */
  background-color: transparent; /* 2 */
  background-image: none; /* 2 */
}

/*
Use the modern Firefox focus style for all focusable elements.
*/

.itm-booker :-moz-focusring {
  outline: auto;
}

/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/

.itm-booker :-moz-ui-invalid {
  box-shadow: none;
}

/*
Add the correct vertical alignment in Chrome and Firefox.
*/

.itm-booker progress {
  vertical-align: baseline;
}

/*
Correct the cursor style of increment and decrement buttons in Safari.
*/

.itm-booker ::-webkit-inner-spin-button,
.itm-booker ::-webkit-outer-spin-button {
  height: auto;
}

/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/

.itm-booker [type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/*
Remove the inner padding in Chrome and Safari on macOS.
*/

.itm-booker ::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
Removes the default spacing and border for appropriate elements.
*/

.itm-booker blockquote,
.itm-booker dl,
.itm-booker dd,
.itm-booker h1,
.itm-booker h2,
.itm-booker h3,
.itm-booker h4,
.itm-booker h5,
.itm-booker h6,
.itm-booker hr,
.itm-booker figure,
.itm-booker p,
.itm-booker pre,
.itm-promotion-portal h1,
.itm-promotion-portal h2,
.itm-promotion-portal h3,
.itm-promotion-portal h4,
.itm-promotion-portal h5,
.itm-promotion-portal h6,
.itm-promotion-portal p{
  margin: 0;
}

.itm-booker ol,
.itm-booker ul,
.itm-booker menu,
.itm-promotion-portal ol,
.itm-promotion-portal ul,
.itm-promotion-portal menu
{
  list-style: none;
  margin: 0;
  padding: 0;
}

/*
Reset default styling for dialogs.
*/
.itm-booker dialog {
  padding: 0;
}

/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/

.itm-booker input::placeholder,
.itm-booker textarea::placeholder {
  opacity: 1; /* 1 */
  color: theme('colors.gray.400', #9ca3af); /* 2 */
}

/*
Set the default cursor for buttons.
*/

.itm-booker button,
.itm-promotion-portal button,
[role="button"] {
  cursor: pointer;
}

/*
Make sure disabled buttons don't get the pointer cursor.
*/
.itm-booker :disabled {
  cursor: default;
}
@tailwind utilities;

.itm-promotion-portal .itm-btn{
   @apply tw-text-[14px]
}
.itm-booker .itm-btn, .itm-promotion-portal .itm-btn{
   @apply tw-relative tw-leading-normal tw-py-1.5 tw-px-3 tw-border tw-rounded-md tw-font-medium focus:tw-outline-none disabled:tw-opacity-50
}
.itm-booker .itm-btn--animated, .itm-promotion-portal .itm-btn--animated {
   @apply tw-relative tw-transition-all
}
.itm-booker .itm-btn-bs--primary, .itm-promotion-portal .itm-btn-bs--primary{
   @apply tw-border-primary tw-text-primary-a11y hover:tw-bg-primary-10 tw-bg-primary
}
.itm-booker .itm-btn-bs--primary-outline, .itm-promotion-portal .itm-btn-bs--primary-outline{
   @apply tw-border-primary tw-text-primary tw-bg-transparent hover:tw-bg-primary hover:tw-text-primary-a11y
}
.itm-booker .itm-btn--circle{
   @apply tw-relative tw-leading-normal tw-p-0 tw-border tw-rounded-full tw-font-medium focus:tw-outline-none disabled:tw-opacity-50
}
.itm-booker .itm-btn-arrow{
   @apply tw-absolute tw-top-1/2 tw-right-[1em] -tw-my-[0.7em] -tw-mx-[0.3em]
}
.itm-booker .itm-btn-arrow--invisible{
   @apply tw-invisible
}
.itm-booker .itm-btn-arrow--loading{
   @apply tw-absolute tw-top-1/2 tw-right-[1em] -tw-m-[0.7em]
}

@media (min-width: 768px) {
   .itm-booker--horizontal .itm-booker-field{
      padding-left: 1rem;
      padding-right: 1rem;
      line-height: 65px;
   }
   .itm-booker--horizontal .itm-drs .DateRangePicker .DateInput_input{
      line-height: 61px;
   }
}

.itm-promotion-link{
      color: #3182ce;
      font-size: 1rem;
      cursor: pointer;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 0;
      padding-right: 0;
      line-height: 1.25;
      display: inline-block;

}
.itm-promotion-link:hover{
      color: #2c5282;
      text-decoration: underline;
}
.itm-popup-left {
   display: grid;
   grid-template-columns: 1fr 1fr;
   text-align: left !important;
}
.itm-popup-left .itm-popup-text {
   padding: 0 16px;
   display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 16px;
}
.itm-popup-left .itm-popup-image  {
   min-height: 400px;
   grid-column: 1;
    grid-row: 1;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
}
.itm-popup-right {
   display: grid;
   grid-template-columns: 1fr 1fr;
   text-align: right !important;
}
.itm-popup-right .itm-popup-image {
   min-height: 400px;
   grid-column: 2;
   grid-row: 1;
   border-top-right-radius: 5px;
   border-bottom-right-radius: 5px;
}
.itm-popup-right .itm-popup-text {
   grid-column: 1;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   padding: 0 16px;
}

.itm-popup-mid .itm-popup-image {
   border-top-right-radius: 5px;
   border-top-left-radius: 5px;
   height: 250px;
}
.itm-popup-mid .itm-popup-text {
   padding: 0 16px;
}
