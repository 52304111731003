.CalendarDay__default {
   position: relative;
   outline: 0;
}
@media (min-width: 768px) {
   .itm-booker--horizontal .itm-booker-field-container:first-child .itm-booker-field {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
   }

   .itm-booker--horizontal .itm-booker-field-container:last-child .itm-booker-field {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
   }
}