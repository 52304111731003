.itm-sds .SingleDatePicker{
   display: block;
}
.itm-sds .SingleDatePickerInput{
   display: flex;
   border-radius: 0px;
}
.itm-sds .SingleDatePickerInput .DateInput{
   display: block;
   flex-grow: 1;
}
.itm-sds .SingleDatePickerInput .DateInput .DateInput_input{
   font-size: 16px;
   font-weight: 400;
   line-height: 41px;
   color: inherit;
   padding: 0px 10px 0;
   border-top: 2px solid transparent;
}
.itm-sds .SingleDatePicker .SingleDatePickerInput_calendarIcon{
   padding: 0;
   margin:-2px 0 0 12px
}
.itm-sds .SingleDatePicker .SingleDatePickerInput_calendarIcon_svg{
   fill:#565a5c
}
.itm-sds .SingleDatePicker button.SingleDatePickerInput_clearDate {
   display: flex;
   padding-left: 0px;
   padding-right: 0px;
   align-items: center;
   justify-content: center;
   margin: 0 10px 0 5px;
}
.itm-sds .SingleDatePicker button.SingleDatePickerInput_clearDate:hover{
   background:none;
}
.itm-sds.itm-sds--error .SingleDatePicker .SingleDatePickerInput__withBorder{
   border-bottom-color:rgb(248, 113, 113);
}
