.itm-drs .DateRangePicker
.DateInput{
   width: 40%;
}
.itm-drs.itm-drs--error .DateRangePicker
.DateRangePickerInput__withBorder{
   border-bottom-color:rgb(248, 113, 113);
}
.itm-drs .DateRangePicker
.DateRangePickerInput{
   border-radius: 3px;
   white-space: nowrap;
}
@media (min-width: 768px){
   .itm-drs.itm-drs-h .DateRangePicker
   .DateRangePickerInput{
      border-radius: 0px;
   }
}
.itm-drs .DateRangePicker
.DateRangePickerInput__withBorder {
   border-color:rgb(229, 231, 235);
}
.itm-drs .DateRangePicker
.DateRangePickerInput_arrow{
   width: 10%;
}
.itm-drs .DateRangePicker
.DateRangePickerInput_calendarIcon{
   padding: 0;
   margin:-2px 0 0 12px
}
.itm-drs .DateRangePicker
.DateRangePickerInput_calendarIcon_svg{
   fill:#565a5c
}
.itm-drs .DateRangePicker
.DateInput_input{
   font-size: 16px;
   font-weight: 400;
   line-height: 41px;
   color: inherit;
   padding: 0px 10px 0;
   border-top: 2px solid transparent;
}
@media screen and (min-width: 768px) and (max-width: 992px){
   /* .itm-booker--horizontal .itm-booker-field-container, */
   .itm-booker--horizontal .itm-booker-field-container .itm-drs .DateRangePicker .DateInput_input{
      font-size: 12px;
   }
}

.itm-drs .DateRangePicker button.DateRangePickerInput_clearDates {
   display: flex;
   padding-left: 0px;
   padding-right: 0px;
   align-items: center;
   justify-content: center;
   margin: 0 10px 0 5px;
}
.itm-drs .DateRangePicker button.DateRangePickerInput_clearDates:hover{
   background:none;
}
.itm-drs .DateRangePicker svg,
.DateRangePicker_picker svg{
   display: inline;
}
.itm-drs .DateRangePicker,
.itm-drs .DateRangePickerInput{
   display: block;
}
.itm-drs-rate{
   display: block;
   color: rgb(156 163 175)
}
.itm-drs-lowestRate{
   display: block;
   color: rgb(22 163 74)
}
.itm-drs-rate-corner{
   position: absolute;
   top: 0;
   left: 0;
   /* @apply absolute top-0 left-0 before:border-r-transparent before:border-t-red-400 before:border-r-8 before:border-t-8 before:h-0 before:w-0 before:block before:content-[''] */
}
.itm-drs-rate-corner::before{
   content: "";
   border: 0px solid;
   border-top-color: rgb(248 113 113);
   border-right-color: transparent;
   border-right-width: 8px;
   border-top-width: 8px;
   width: 0;
   height: 0;
   display: block;
}
.DateRangePicker_picker{
   z-index: 1080;
}
.DayPickerNavigation_button__verticalDefault{
   box-sizing: border-box;
}

.DateRangePicker_picker .info-panel {
   display: inline-block;
   padding: 0 22px;
   font-size: 13px;
}
@media(max-width: 767px) {
   .DateRangePicker_picker .info-panel {
      z-index: 2;
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      bottom: 52px;
      padding: 1rem 1.5rem 0;
      box-shadow: 0px -2px 5px #ddd;
      background-color: white;
   }
}
.DateRangePicker_picker .info-panel .itm-info-panel-symbols {
   display: flex;
   column-gap: 24px;
   align-items: center;
}

.DateRangePicker_picker .info-panel .itm-info-panel-symbols .itm-info-panel-symbol {
   display: flex;
   align-items: center;
   column-gap: 4px;
}

.DateRangePicker_picker .info-panel .itm-info-panel-symbols .itm-info-panel-symbol .itm-info-panel-symbol-icon {
   display: flex;
   border: 1px solid #ddd;
   width: 16px;
   height: 16px;
   justify-content: center;
   align-items: center;
}

.DateRangePicker_picker .info-panel .itm-info-panel-symbols .itm-info-panel-symbol .itm-info-panel-symbol-icon.itm-info-panel-symbol-icon--lowestRate {
   color: #28a745;
}

.DateRangePicker_picker .info-panel .itm-info-panel-legend{
   color: #AAA;
   padding: 13px 0;
}

.itm-drs-withoutRate{
   display: block;
}
.itm-drs-bookerDay small {
   opacity: .7;
}

.itm-drs-bookerDay small,
.itm-drs-bookerDay .itm-drs-fetching {
   display: block;
   margin-top: 2px;
}

.itm-drs-bookerDay .itm-drs-fetching {
   height: 1.4em;
   padding-top: .4em;
}

.itm-drs-bookerDay .itm-drs-fetching:before {
   content: "";
   display: block;
   background-color: rgba(0, 0, 0, 0.2);
   border-radius: .4em;
   height: .8em;
   width: 70%;
   margin-left: auto;
   margin-right: auto;
   animation: fetchingBlink 0.8s ease-out;
   animation-iteration-count: infinite;
}

.itm-drs-bookerDay .itm-drs-withoutRate {
   display: block
}

@keyframes fetchingBlink {
   0% {
       opacity: .5;
   }
   50% {
       opacity: 1;
   }
   100% {
       opacity: .5;
   }
}

.itm-drs-bookerDay .itm-drs-lowestRate {
   color: #28a745;
}
.CalendarDay__selected .itm-drs-bookerDay .itm-drs-lowestRate {
   color: #82f89e;
   font-weight: bold;
}

.CalendarDay__selected_span {
   background: rgba(var(--color-calendarDay-spanBg));
   border: 1px solid rgba(var(--color-calendarDay-spanBorder));
   color: #fff;
}

.CalendarDay__selected_span:active, 
.CalendarDay__selected_span:hover,
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span:active {
   background: rgba(var(--color-calendarDay-spanHoverBg));
   border-color: rgba(var(--color-calendarDay-spanHoverBorder));
   color: rgba(var(--color-calendarDay-spanHoverColor));
   outline: none;
}

.CalendarDay__selected_start, 
.CalendarDay__selected_end, 
.CalendarDay__selected_start:active, 
.CalendarDay__selected_start:hover,
.CalendarDay__selected_end:active, 
.CalendarDay__selected_end:hover {
   background: rgba(var(--color-calendarDay-selectedBg));
   border: 1px solid rgba(var(--color-calendarDay-selectedBorder));
   color: #fff;
}
.DateRangePickerInput_arrow_svg{
   width:  100%;
}